export default class CheckUserDetails {
    key = 'userDetails';
    expiry = 3; // hours
    userDetails = null;
    userType = null;

    constructor() {
        this.now = new Date();
        this.userDetails = this.getUserDetails();
        this.userType = this.getUserType();
    }

    async fetchUserDetails(email = '') {
        if (this.userDetails === null || this.userType === null) {
            const formData = new FormData();
            formData.append('action', 'check_user_details');
            formData.append('securityToken', wp_ajax.security);
            formData.append('email', email);

            const request = await fetch(wp_ajax.ajax_url, {
                method: 'POST',
                credentials: 'same-origin',
                body: formData,
            });

            let response = await request.json();

            if ('userDetails' in response) {
                let newsletterSubscribed = this.getNewsletterSubscribed();
                if (newsletterSubscribed !== null) {
                    response.userDetails.newsletterSubscribed = newsletterSubscribed;
                } else {
                    this.setNewsletterSubscribed(response.userDetails?.newsletterSubscribed);
                }

                this.setUserType(response.userDetails?.user_type);
                this.setUserDetails(response.userDetails);
            } else {
                console.error('User Details Check error: No response');
            }

            if (response.status !== 'success') {
                console.error('User Details Check error: ' + response);
            }
        }
    }

    setUserDetails(userDetails) {
        localStorage.setItem(
            this.key, 
            JSON.stringify({
                value: userDetails,
                expiry: this.now.getTime() + (this.expiry * 3600000),
            })
        );
        this.userDetails = userDetails;
    }

    getUserDetails() {
        let item = localStorage.getItem(this.key)
        if (!item) {
            return null;
        }

        item = JSON.parse(item);
        if (this.now.getTime() > item.expiry) {
            localStorage.removeItem(this.key);
            return null;
        }

        if (item?.value === 'undefined') {
            return null;
        }

        return item?.value;
    }

    setUserType(userType = 'anonymous') {
        let expiry = new Date();
        expiry.setMonth(expiry.getMonth() + 2);
        document.cookie = `user_type=${userType}; path=/; expires=${expiry.toUTCString()}`;
        this.userType = userType;
    }

    getUserType() {
        return document.cookie.match('(^|;)\\s*user_type\\s*=\\s*([^;]+)')?.pop() || null;
    }

    setNewsletterSubscribed(newsletterSubscribed = false) {
        let expiry = new Date();
        expiry.setMonth(expiry.getMonth() + 2);
        document.cookie = `newsletterSubscribed=${newsletterSubscribed}; path=/; expires=${expiry.toUTCString()}`;
    }

    getNewsletterSubscribed() {
        const value = document.cookie.match('(^|;)\\s*newsletterSubscribed\\s*=\\s*([^;]+)')?.pop() || null;
        return value !== null && value !== '' ? JSON.parse(value) : value;
    }

    async renderAds() {
        await this.fetchUserDetails();

        window.admiral = window.admiral || function() {(admiral.q = admiral.q || []).push(arguments)};
        window.admiral("targeting", "set", "userType", JSON.stringify(this.userType));
        window.admiral("targeting", "ready");
        console.log('Ad Type: ' + JSON.stringify(this.userType));

        const adTargets = document.querySelectorAll('[data-behaviour="ad-target"]');
        if (adTargets.length > 0) {
            if (this.userDetails.disableAds) {
                adTargets.forEach(adTarget => {
                    const adContainer = adTarget.closest('[data-target="ad-container"]');
                    if (adContainer !== null) {
                        adContainer.classList.add('u-hidden');
                        console.log('Ad Hide: ' + adTarget.getAttribute('id'));
                    }
                });
            } else {
                adTargets.forEach(adTarget => {
                    try {
                        googletag.cmd.push(function () {
                            googletag.display(adTarget.getAttribute('id'));
                            console.log('Ad Render: ' + adTarget.getAttribute('id'));
                        });
                    } catch (error) {
                        console.log('Ad Render Error: ' + error);
                    }
                });
            }
        }
    }
}
