import React, { Suspense } from 'react';
import ReactRender from '../tools/react-render';

import './TagAutomation';
import './Paywall';

const basketDataEl = document.querySelector('[data-behaviour="react-basket-popup"]');
if (basketDataEl) {
    const BasketPopup = React.lazy(() => import('./BasketPopup'));
    const BasketApp = () => (
        <Suspense fallback={<></>}>
            <BasketPopup />
        </Suspense>
    );

    ReactRender(<BasketApp />, basketDataEl);
}

const accountPopupEl = document.querySelector('[data-behaviour="react-account-popup"]');
if (accountPopupEl) {
    const AccountPopup = React.lazy(() => import('./AccountPopup'));
    let accountSettings = [];
    accountSettings = JSON.parse(accountPopupEl.getAttribute('data-settings'));

    const BasketApp = () => (
        <Suspense fallback={<></>}>
            <AccountPopup settings={accountSettings} />
        </Suspense>
    );

    ReactRender(<BasketApp />, accountPopupEl);
}

const databaseTable = document.querySelector('[data-behaviour="react-database-table"]');
if (databaseTable) {
    const DatabaseList = React.lazy(() => import('./Database/DatabaseList'));
    const databaseType = databaseTable.getAttribute('data-database');

    const DatabaseTableApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <DatabaseList type={databaseType} />
        </Suspense>
    );

    ReactRender(<DatabaseTableApp />, databaseTable);
}

const reactSearch = document.querySelector('[data-behaviour="react-adv-search"]');
if (reactSearch) {
    const SearchPage = React.lazy(() => import('./Search/Page/SearchPage'));
    const SearchPageApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <SearchPage />
        </Suspense>
    );

    ReactRender(<SearchPageApp />, document.querySelector('[data-behaviour="react-adv-search"]'));
}

if (document.querySelector('[data-behaviour="react-search-overlay"]')) {
    const SearchOverlay = React.lazy(() => import('./Search/Overlay/SearchOverlay'));

    const SearchOverlayApp = () => (
        <Suspense fallback={<div></div>}>
            <SearchOverlay />
        </Suspense>
    );

    ReactRender(<SearchOverlayApp />, document.querySelector('[data-behaviour="react-search-overlay"]'));
}

if (document.querySelector('[data-behaviour="react-subscription-variations"]')) {
    const SubscriptionVariations = React.lazy(() => import('./SubscriptionVariations'));

    const attributesData = JSON.parse(
        document.querySelector('[data-behaviour="react-subscription-variations"]').getAttribute('data-attributes'),
    );
    const subscriptionDatesData = JSON.parse(
        document
            .querySelector('[data-behaviour="react-subscription-variations"]')
            .getAttribute('data-subscription-dates'),
    );
    const variationsData = JSON.parse(
        document.querySelector('[data-behaviour="product-variations-form"]').getAttribute('data-product_variations'),
    );
    const selectedVariationSku = document
        .querySelector('[data-behaviour="react-subscription-variations"]')
        .getAttribute('data-selected-variation-sku');

    const SubscriptionVariationsApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <SubscriptionVariations
                attributes={attributesData}
                dates={subscriptionDatesData}
                variations={variationsData}
                selectedSku={selectedVariationSku}
            />
        </Suspense>
    );

    ReactRender(
        <SubscriptionVariationsApp />,
        document.querySelector('[data-behaviour="react-subscription-variations"]'),
    );
}

if (document.querySelector('[data-behaviour="react-colour-attributes"]')) {
    const ColourAttributes = React.lazy(() => import('./ProductAttributes'));
    const attributesData = JSON.parse(
        document.querySelector('[data-behaviour="react-colour-attributes"]').getAttribute('data-colour-attributes'),
    );

    const ColourAttributesApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <ColourAttributes attributes={attributesData} />
        </Suspense>
    );

    ReactRender(<ColourAttributesApp />, document.querySelector('[data-behaviour="react-colour-attributes"]'));
}

if (document.querySelector('[data-behaviour="react-product-filters"]')) {
    const ProductFilters = React.lazy(() => import('./ProductFilters'));

    const filtersData = JSON.parse(
        document.querySelector('[data-behaviour="react-product-filters"]').getAttribute('data-react-json'),
    );
    const category = document
        .querySelector('[data-behaviour="react-product-filters"]')
        .getAttribute('data-category-name');
    const sort = document
        .querySelector('[data-behaviour="react-product-filters"]')
        .getAttribute('data-sort');
    const sales = document
        .querySelector('[data-behaviour="react-product-filters"]')
        .getAttribute('data-sales');
    const featured = document
        .querySelector('[data-behaviour="react-product-filters"]')
        .getAttribute('data-featured');

    const ProductFiltersApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <ProductFilters filters={filtersData} category={category} sort={sort} sales={sales} featured={featured} />
        </Suspense>
    );

    ReactRender(<ProductFiltersApp />, document.querySelector('[data-behaviour="react-product-filters"]'));
}

if (document.querySelectorAll('[data-behaviour="react-update-subscription-address"]')) {
    const UpdateSubscriptionAddress = React.lazy(() => import('./Subscriptions/UpdateAddress'));
    const subscriptionForms = document.querySelectorAll('[data-behaviour="react-update-subscription-address"]');

    subscriptionForms.forEach(form => {
        const subscriptionId = JSON.parse(form.getAttribute('data-subscription-id'));

        const subscription = JSON.parse(form.getAttribute('data-subscription'));
        const multiple = JSON.parse(form.getAttribute('data-multiple'));

        const UpdateSubscriptionAddresssApp = () => (
            <Suspense fallback={<div>Loading...</div>}>
                <UpdateSubscriptionAddress
                    subscription={subscription}
                    subscriptionId={subscriptionId}
                    multiple={multiple}
                />
            </Suspense>
        );

        ReactRender(<UpdateSubscriptionAddresssApp />, form);
    });
}

const subscriptionsEl = document.querySelector('[data-behaviour="react-subscriptions"]');
if (subscriptionsEl) {
    const Subscriptions = React.lazy(() => import('./Subscriptions/Subscriptions'));
    const productID = subscriptionsEl.getAttribute('data-id');
    const checkoutUrl = subscriptionsEl.getAttribute('data-checkout-url');

    const SubscriptionsApp = () => (
        <Suspense fallback={<div></div>}>
            <Subscriptions id={productID} checkoutUrl={checkoutUrl} />
        </Suspense>
    );

    ReactRender(<SubscriptionsApp />, subscriptionsEl);
}

const puzzlesEl = document.querySelector('[data-behaviour="react-puzzles"]');
if (puzzlesEl) {
    const Puzzles = React.lazy(() => import('./Puzzles'));
    const PuzzlesApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <Puzzles />
        </Suspense>
    );

    ReactRender(<PuzzlesApp />, puzzlesEl);
}

const issueCarouselEl = document.querySelector('[data-behaviour="issue-carousel"]');
if (issueCarouselEl) {

    const IssueCarousel = React.lazy(() => import('./IssueCarousel'));
    const pages = issueCarouselEl.getAttribute('data-pages');
    const issue = issueCarouselEl.getAttribute('data-issue');
    const count = issueCarouselEl.getAttribute('data-count');
    const start = issueCarouselEl.getAttribute('data-start-index');
    const path = issueCarouselEl.getAttribute('data-path');
    const type = issueCarouselEl.getAttribute('data-type');
    const id = issueCarouselEl.getAttribute('data-issue-id');
    const paywall = JSON.parse(issueCarouselEl.getAttribute('data-paywall'));

    const IssueCarouselApp = () => (
        <Suspense fallback={<div className="issue-slider issue-slider--loading">
        <div className="issue-slider__list">
          {Array.from(
            { length: 6 },
            (_, i) => <div key={i} className="issue-slider__item"><div className="issue-slider__page"></div></div>
          )}
        </div>
      </div>}>
            <IssueCarousel pages={pages} issue={issue} id={id} count={count} start={start} type={type} paywall={paywall} path={path} />
        </Suspense>
    );

    ReactRender(<IssueCarouselApp />, issueCarouselEl);
}

const circuitsEl = document.querySelectorAll('[data-behaviour="react-circuits"]');
if (circuitsEl) {
    const Circuits = React.lazy(() => import('./Database/Circuits'));
    
    circuitsEl.forEach(circuit => {
        const circuitData = circuit.getAttribute('data-circuits');

        const CircuitsApp = () => (
            <Suspense fallback={<div></div>}>
                <Circuits data={circuitData} />
            </Suspense>
        );
    
        ReactRender(<CircuitsApp />, circuit);
    });

   
}
