import React, { Suspense } from 'react';
import ReactRender from '../../tools/react-render';

const getViewedPosts = () => {
    let viewedPostsLocalStorage = localStorage.getItem('viewedPosts');
    let viewedPostsJson = [];

    if (!viewedPostsLocalStorage) {
        const viewedPostsData = {
            posts: [],
            createdAt: new Date(),
        };

        localStorage.setItem('viewedPosts', btoa(JSON.stringify(viewedPostsData)));
        viewedPostsLocalStorage = localStorage.getItem('viewedPosts');
    }

    if (viewedPostsLocalStorage) {
        viewedPostsJson = JSON.parse(atob(viewedPostsLocalStorage));
    }

    if (viewedPostsJson) {
        // Check if local storage data has expired
        const { createdAt } = viewedPostsJson;
        const now = new Date();
        const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
        const timeDiffInMs = now.getTime() - Date.parse(createdAt);

        if (timeDiffInMs >= thirtyDaysInMs) {
            // Data has expired, reset local storage data
            const viewedPostsData = {
                posts: [],
                createdAt: now,
            };

            localStorage.setItem('viewedPosts', btoa(JSON.stringify(viewedPostsData)));
            viewedPostsJson = JSON.parse(atob(viewedPostsLocalStorage));
        }
    }

    return viewedPostsJson.posts;
};

const paywallContentThreshold = document.querySelector('[data-behaviour="react-paywall-content-threshold"]');

if (paywallContentThreshold) {

    const PaywallContentThreshold = React.lazy(() => import('./PaywallContentThreshold'));
    const postId = paywallContentThreshold.dataset.postId;

    const PaywallContentThresholdApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <PaywallContentThreshold postId={postId} getViewedPosts={getViewedPosts} />
        </Suspense>
    );

    ReactRender(<PaywallContentThresholdApp />, paywallContentThreshold);
}

const paywallSliderThreshold = document.querySelector('[data-behaviour="react-paywall-slider-threshold"]');

if (paywallSliderThreshold) {

    const PaywallSliderThreshold = React.lazy(() => import('./PaywallSliderThreshold'));
    const postId = paywallSliderThreshold.dataset.postId;

    const PaywallSliderThresholdApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <PaywallSliderThreshold postId={postId} getViewedPosts={getViewedPosts} />
        </Suspense>
    );

    ReactRender(<PaywallSliderThresholdApp />, paywallSliderThreshold);
}
